import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>

            Hi, I am <span className="purple">Himanshu Singh </span>
            from <span className="purple"> Greater Noida, Uttar Pradesh, </span>
            a Computer Science and Engineering graduate from ITS Engineering College, AKTU, currently working at JTEKT Electronics India
            as <span className="purple"> Associate Software Engineer </span>
            <br />
            I am always excited to connect with like-minded professionals and explore opportunities to collaborate on innovative projects. If you share a passion for technology and innovation, let’s connect and create something remarkable together!
            <br />
            <br />
            Apart from coding, my hobbies are!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Cricket
            </li>
            <li className="about-activity">
              <ImPointRight /> Swimming
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Himanshu Singh</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
