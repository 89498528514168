import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import "./Project.css"

function ProjectCards(props) {
  console.log(props)
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={"https://res.cloudinary.com/dtozqq0us/image/upload/v1723358830/" + props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {props.badges.map((badge) => {
          return (<span className="badge" style={{ backgroundColor: badge.color ? badge.color : 'grey' }}>{badge.name}</span>)
        })}
        <br />
        <Button variant="primary" href={props.ghLink} target="_blank" style={{ margin: "10px" }}>
          <BsGithub /> &nbsp;
          {props.isBlog ? "Blog" : "GitHub"}
        </Button>
        {"\n"}
        {"\n"}



        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ margin: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
